import React from 'react';
import PropTypes from 'prop-types';
import DropdownList from 'react-widgets/lib/DropdownList';
import cs from 'classnames';
import { get, isString } from 'lodash';

const LabeledDropdown = ({
  input: { onChange, value, ...input },
  meta = {},
  data,
  label,
  labelProps = {},
  containerProps = {},
  placeholder,
  textField = 'text',
  valueField = 'value',
  horizontal,
  submitOnChange = false,
  onValueChange = () => ({}),
  onObjectChange = () => ({}),
  hiddenValues = [],
  ...rest
}) => {
  // Normalize the data to support string arrays
  const normalizedData = data.map(item =>
    isString(item) ? { text: item, value: item } : item
  );

  const filteredData = normalizedData.filter(
    item => !hiddenValues.includes(get(item, valueField))
  );

  const selectedItem = normalizedData.find(item => get(item, valueField) === value);

  return (
    <div
      className={cs('form-group', { 'has-error': meta.touched && meta.error })}
      {...containerProps}
    >
      {label ? (
        <label
          {...labelProps}
          className={cs('control-label', labelProps.className, {
            'col-xs-2': horizontal,
          })}
        >
          {label}
        </label>
      ) : null}
      <div
        className={cs({
          'col-xs-10': horizontal,
        })}
      >
        <DropdownList
          data={filteredData}
          placeholder={placeholder || ''}
          textField={textField}
          valueField={valueField}
          value={selectedItem || ''}
          onChange={val => {
            const selectedValue = get(val, [valueField], val);
            onValueChange(selectedValue);
            onChange(selectedValue);
            onObjectChange(val);
            if (submitOnChange) {
              setTimeout(submitOnChange);
            }
          }}
          {...input}
          {...rest}
        />
      </div>
      {meta.touched && meta.error && (
        <div className="help-block">{meta.error}</div>
      )}
    </div>
  );
};

LabeledDropdown.propTypes = {
  data: PropTypes.array.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  hiddenValues: PropTypes.array, // Add prop type for hiddenValues
};

export default LabeledDropdown;
