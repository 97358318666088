import React from 'react';
import { connect } from 'react-redux-v5';
import { compose } from 'recompose';
import { Field, reduxForm, change, formValueSelector } from 'redux-form';
import get from 'lodash/get';
import { editProfile } from 'actions/resource/user';
import { openModal } from 'actions/ui'
import { USER_TYPE } from 'helpers';
import colors from 'styles/colors.json';
import MaterialIcon from 'components/pure/MaterialIcon';
import Tooltip from 'components/pure/Tooltip';
import SubmitButton from 'components/pure/form/SubmitButton';
import Toggle from 'material-ui/Toggle';
import getSelf from 'selectors/getSelf';



function SMSAlertForm({
    userType,
    handleSubmit,
    submitting,
    has_verified_email,
    change,
    enableSMSAlerts
}) {

    const TooltipText = <div style={{ display: "flex", flexDirection: "column", textAlign: "left" }}>
        <p>If enabled, we'll send you a text(SMS) when there are urgent matters that need your attention, such as:</p>
        <div style={{ padding: 2 }}>
            <p>- There are document issues with a funding request</p>
            <p>- You are underbid on a bid</p>
            <p>- And others</p>
        </div>
        <p>Depending on your activity on our platform, you can expect between 1 text every month to 1 text per day.</p>
        <div style={{ padding: 2 }}>
            <p>Charges may apply.</p>
        </div>
    </div >

    return [USER_TYPE.CARRIER, USER_TYPE.BROKER].includes(userType) ? <div>
        <table className='table CheckmarkTable'>
            <thead>
                <tr>
                    <th className='CheckmarkTable__header'>Text Message Notifications</th>
                </tr>
            </thead>
            <tbody className='MultiCheckmarkInput'>
                <tr>
                    <td>Enable Text Notifications
                        <Tooltip
                            text={TooltipText}
                            placement="top"
                        >
                            <MaterialIcon
                                name="error_outline"
                                size={20}
                                style={{ color: colors.BLUE_BTN }}
                            />
                        </Tooltip>
                    </td>
                    <td style={{ width: '50%' }}></td>
                    <td>
                        <Field
                            name='enable_sms_alerts'
                            component={Toggle}
                            defaultToggled={enableSMSAlerts}
                            onToggle={(value, isInputChecked) => {
                                change('enable_sms_alerts', isInputChecked);
                            }}
                        />
                    </td>
                </tr>
            </tbody>
        </table>
        <SubmitButton onClick={handleSubmit} disabled={submitting}>SAVE</SubmitButton>
    </div>
    : <div></div>
}
const FORM_NAME = 'SettingsNotificationsSMSAlertsForm';
const selector = formValueSelector(FORM_NAME)
export default compose(
    connect(state => {
        const self = getSelf(state);
        if (self) {
            return {
                userType: self.data.type,
                has_verified_email: self.data.has_verified_email,
                userId: self.data.id,
                initialValues: {
                    enable_sms_alerts: get(self, 'data.enable_sms_alerts', false)
                },
                enableSMSAlerts: selector(state, 'enable_sms_alerts'),
            };
        }
        return {};
    }, ({
        change
    })),
    reduxForm({
        form: FORM_NAME,
        destroyOnUnmount: false,
        enableReinitialize: true,
        onSubmit: async (fields, dispatch, { userId }) => {
            try {
                await dispatch(editProfile(userId, fields));
                dispatch(openModal('success', { message: 'Successfully updated settings.' }));
            } catch (e) {
                dispatch(openModal('error', { message: e }));
            }
        }
    })
)(SMSAlertForm);
