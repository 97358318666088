import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import Header from 'components/pure/Header';
import { closeModal } from 'actions/ui';


const SMSAlertsModel = compose(
  connect(
    null,
    dispatch => ({
      closeModal() {
        dispatch(closeModal());
      },
    }),
  ),
)(({ closeModal }) =>
  <div>
    <div className='modal-header'>
      <button type='button' className='close' aria-label='Close' onClick={closeModal}><span aria-hidden='true'>×</span></button>
      <Header className='text-orange'> Comfreight Text Messaging</Header>
    </div>
      <div className='modal-body'>
          <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', fontSize: 16 }}>
              <p>If enabled, we'll send you a text(SMS) when there are urgent matters that need your attention, such
                  as:</p>
              <div style={{ padding: 2 }}>
                  <p>- There are document issues with a funding request</p>
                  <p>- You are underbid on a bid</p>
                  <p>- And others</p>
              </div>
              <p>Depending on your activity on our platform, you can expect between 1 text every month to 1 text per
                  day.</p>
              <div style={{ padding: 2 }}>
                  <p>Charges may apply.</p>
              </div>
          </div>
      </div>
      <div className="modal-footer">
          <button className='btn btn-orange btn-block' type='button' onClick={closeModal}>Ok</button>
      </div>
  </div>
);

export default SMSAlertsModel;
