/**
 * Component that handles all modal popups. Will default to showing the 'success' modal if no 'type' is present, or an undefined 'type' is present
 * @module components/pure/Modals
 * @since 3.0.0
 */
import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/lib/Modal';

import { Generic } from './Generic';
import ModalError from './Error';
import ModalWarning from './Warning';
import ModalSuccess from './Success';
import ModalDelete from './Delete';
import ConfirmBidRetract from './ConfirmBidRetract';
import UserRate from './UserRate';
import ConfirmHidePost from './ConfirmHidePost';
import ConfirmHideUser from './ConfirmHideUser';
import FullAccessBenefits from './FullAccessBenefits';
import ConfirmCancelAccount from './ConfirmCancelAccount';
import UpgradeAccount from './UpgradeAccount';
import ConfirmFactoringApply from './ConfirmFactoringApply';
import TAFSRegister from './TAFSRegister';
import ImagePreview from './ImagePreview';
import ShipperApprovalRequest from './ShipperApprovalRequest';
import ShipperLoadAlertModalForm from './ShipperLoadAlertModalForm';
import ConfirmUnhideUser from './ConfirmUnhideUser';
import BidCreatePerMile from './BidCreatePerMile';
import LegacyDeactivate from './LegacyDeactivate';
import LegacyPrompt from './LegacyPrompt';
import LoginPrompt from './LoginPrompt';
import YoutubeModal from './YoutubeModal';
import ConfirmActivityDelete from './ConfirmActivityDelete';
import FirstTruckPostAlertSettings from './FirstTruckPostAlertSettings';
import Confirm from './Confirm';
import FactoringPaymentRequestMoreDetails from './FactoringPaymentRequestMoreDetails';
import FactoringClientAddDebtor from './FactoringClientAddDebtor';
import PaymentInformation from './PaymentInformation';
import factoringReleaseFunds from './factoringReleaseFunds';
import AddCardInfo from './AddCardInfo';
import AddBankInfo from './AddBankInfo';
import AddWireInfo from './AddWireInfo';
import PaymentSummary from './PaymentSummary';
import LoadUploadExcel from './LoadUploadExcel';
import PaymentRequestFormHelp from './PaymentRequestFormHelp';
import SearchFMCSA from './SearchFMCSA';
import DispatchLoad from './DispatchLoad';
import ConfirmDispatchLoad from './ConfirmDispatchLoad';
import CarrierSignUpExtraForm from './CarrierSignUpExtraForm';
import ShipperSignUpExtraForm from './ShipperSignUpExtraForm';
import PlaceNOA from './PlaceNOA';
import FactoringClientDebtorRelationAuditLog from './FactoringClientDebtorRelationAuditLog';
import FactoringTransactionPaymentModal from './FactoringTransactionPaymentModal';
import FactoringTransactionPurchaseModal from './FactoringTransactionPurchaseModal';
import FactoringTransactionsViewModal from './FactoringTransactionsViewModal';
import FactoringPaymentTransactionEditForm from './FactoringPaymentTransactionEditForm';
import FactoringPurchaseTransactionEditForm from './FactoringPurchaseTransactionEditForm';
import LineItemCreate from './LineItemCreateModal';
import LineItemView from './LineItemViewModal';
import LineItemsEdit from './LineItemsEditModal';
import DefaultBankInfo from './DefaultBankInfo';
import WireAccounts from './WireAccounts';
import ApprovalConfirm from './ApprovalConfirm';
import SimilarDebtors from './SimilarDebtors';
import SetAsMaster from './SetAsMaster';
import AddCustomerCreditCheck from './AddCustomerCreditCheck';
import Notes from './Notes';
import LineItemCategoryCreate from './LineItemCategoryCreate';
import DebtorPaymentLineItems from './DebtorPaymentLineItems';
import LineItemAuditLog from './LineItemAuditLog';
import PaymentMethods from './PaymentMethods';
import fuelAdvanceModal from './FuelAdvanceModal';
import DebtorAmountLimit from './DebtorAmountLimit';
import DebtorAdvancedAmount from './DebtorAdvancedAmount';
import FailedPayment from './FailedPayment';
import ConfirmDebtorOriginals from './ConfirmDebtorOriginals';
import DebtorPastCredit from './DebtorPastCredit';
import MissingDocs from './MissingDocs';
import DraftSaved from './DraftSaved';
import EmptyModal from './EmptyModal';
import AssignCarrierInfoModal from './AssingCarrierInfoModal';
import BrokerDefaultSettings from './BrokerDefaultSettings';
import ResendInvite from './ResendInvite';
import SendNOA from './SendNOA';
import PaymentProfile from './PaymentProfile';
import AddDocsNonFactored from './AddDocsNonFactored';
import CreatePaymentProfile from './CreatePaymentProfile';
import ConfirmationFRPaymentSpeed from './ConfirmationFRPaymentSpeed';
import FileTypeConfirm from './FileTypeConfirm';
import PaymentFundingRequestError from './PaymentFundingRequestError';
import MatchedInvitedCarrier from './MatchedInvitedCarrier';
import LineItemsModal from './LineItemsModal';
import PONumberInvoiceModal from './PONumberInvoiceModal';
import WarningDuplicatePO from './WarningDuplicatePO';
import WalletDepositReceivable from './WalletDepositReceivable';
import StripeCardForm from './StripeCardForm';
import ConfirmMicroDeposit from './ConfirmMicroDeposit';
import CoBrokeringUploadDocs from './CoBrokeringUploadDocs';
import SMSAlertsModel from './SMSAlertsModel';
/**
 * A map from unique 'type's to Components to display that type
 */
export const ModalMap = {
  generic: Generic,
  error: ModalError,
  warning: ModalWarning,
  success: ModalSuccess,
  delete: ModalDelete,
  confirmBidRetract: ConfirmBidRetract,
  userrate: UserRate,
  confirmhidepost: ConfirmHidePost,
  confirmhideuser: ConfirmHideUser,
  fullaccessbenefits: FullAccessBenefits,
  confirmcancelaccount: ConfirmCancelAccount,
  upgradeaccount: UpgradeAccount,
  confirmfactoringapply: ConfirmFactoringApply,
  loginprompt: LoginPrompt,
  tafsregister: TAFSRegister,
  image_preview: ImagePreview,
  shipperapprovalrequest: ShipperApprovalRequest,
  shipperloadalertmodalform: ShipperLoadAlertModalForm,
  confirmunhideuser: ConfirmUnhideUser,
  bidcreatepermile: BidCreatePerMile,
  legacydeactivate: LegacyDeactivate,
  legacyprompt: LegacyPrompt,
  youtube: YoutubeModal,
  confirmactivitydelete: ConfirmActivityDelete,
  firsttruckpostalertsettings: FirstTruckPostAlertSettings,
  confirm: Confirm,
  factoringpaymentrequestmoredetails: FactoringPaymentRequestMoreDetails,
  factoringclientadddebtor: FactoringClientAddDebtor,
  paymentinformation: PaymentInformation,
  addcardinfo: AddCardInfo,
  addbankinfo: AddBankInfo,
  addwireinfo: AddWireInfo,
  loaduploadexcel: LoadUploadExcel,
  requestpaymentformhelp: PaymentRequestFormHelp,
  searchfmcsa: SearchFMCSA,
  dispatchload: DispatchLoad,
  confirmdispatchload: ConfirmDispatchLoad,
  carriersignupextraform: CarrierSignUpExtraForm,
  shippersignupextraform: ShipperSignUpExtraForm,
  placenoa: PlaceNOA,
  factoringclientdebtorrelationauditlog: FactoringClientDebtorRelationAuditLog,
  factoringtransactionpayment: FactoringTransactionPaymentModal,
  factoringtransactionpurchase: FactoringTransactionPurchaseModal,
  factoringtransactionsview: FactoringTransactionsViewModal,
  factoringpaymenttransactioneditform: FactoringPaymentTransactionEditForm,
  factoringpurchasetransactioneditform: FactoringPurchaseTransactionEditForm,
  lineitemcreate: LineItemCreate,
  lineitemview: LineItemView,
  lineitemsedit: LineItemsEdit,
  paymentsummary: PaymentSummary,
  defaultbankinfo: DefaultBankInfo,
  wireaccounts: WireAccounts,
  approvalConfirm: ApprovalConfirm,
  similardebtors: SimilarDebtors,
  setasmaster: SetAsMaster,
  addcustomercreditcheck: AddCustomerCreditCheck,
  notes: Notes,
  lineitemcategorycreate: LineItemCategoryCreate,
  debtorpaymentlineitems: DebtorPaymentLineItems,
  lineitemaudit: LineItemAuditLog,
  paymentmethods: PaymentMethods,
  fuelAdvance: fuelAdvanceModal,
  factoringReleaseFunds: factoringReleaseFunds,
  DebtorAmountLimit,
  DebtorAdvancedAmount,
  FailedPayment,
  ConfirmDebtorOriginals,
  DebtorPastCredit,
  MissingDocs,
  DraftSaved,
  EmptyModal,
  AssignCarrierInfoModal,
  BrokerDefaultSettings,
  ResendInvite,
  SendNOA,
  PaymentProfile,
  AddDocsNonFactored,
  CreatePaymentProfile,
  ConfirmationFRPaymentSpeed,
  FileTypeConfirm,
  PaymentFundingRequestError,
  MatchedInvitedCarrier,
  LineItemsModal,
  PONumberInvoiceModal,
  WarningDuplicatePO,
  WalletDepositReceivable,
  StripeCardForm,
  ConfirmMicroDeposit,
  CoBrokeringUploadDocs,
  SMSAlertsModel,
};

export const ModalContainer = ({ modals }) => {
  let modalContents = null;
  let modalContentsProps = null;
  let modal = null;
  let size = '';
  if (modals.length) {
    const { type, props: { modalSize = '', ...props } = {} } = modals[0];
    let modalType = ModalMap[type];
    modal = modals[0].type;
    if (modalType === undefined) {
      modalType = ModalError;
    }
    modalContents = React.createElement(modalType, { ...props });
    modalContentsProps = modalType ? modalType.containerProps : undefined;
    size = modalSize;
  }
  return (
    modal === 'setasmaster' ?
      <Modal bsSize={size} show={modals.length > 0} {...modalContentsProps}>
        {modalContents}
      </Modal> :
      <Modal bsSize={size} show={modals.length > 0} {...modalContentsProps}>
        {modalContents}
      </Modal>
  );
};

ModalContainer.propTypes = {
  modals: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.oneOf(Object.keys(ModalMap)).isRequired,
    props: PropTypes.object,
  })).isRequired,
};

export default ModalContainer;
